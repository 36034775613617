import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { catchError } from 'rxjs/operators';

import { useActionObservableCallback } from '@perpay-web/hooks/useActionObservableCallback';
import { noop } from '@perpay-web/utils/noop';

export const useReduxDataModuleHookConnection = ({
    hookAction$,
    dataModule,
    request,
    error,
    success,
    reset,
    storybookOverride,
}) => {
    const dispatch = useDispatch();

    const { sideEffect$, dataRequest, dataReset } = dataModule;

    // Fire outbound actions from hook to Redux and epics in response to hook side-effect results.
    useEffect(() => {
        if (window.STORYBOOK && !storybookOverride) {
            return noop;
        }

        const subscription = sideEffect$
            .pipe(
                catchError((e, source$) => {
                    dispatch(error(e));
                    return source$;
                }),
            )
            .subscribe({
                next: (value) => {
                    dispatch(success(value));
                },
            });

        return () => subscription.unsubscribe();
    }, [error, success, dispatch, sideEffect$, storybookOverride]);

    // Listen for inbound actions from Redux and epics to trigger hook side-effects.
    useActionObservableCallback(hookAction$, request().type, (value) => {
        if (window.STORYBOOK && !storybookOverride) {
            return;
        }

        dataRequest(value);
    });

    useActionObservableCallback(hookAction$, reset().type, () => {
        if (window.STORYBOOK && !storybookOverride) {
            return;
        }

        dataReset();
    });
};
